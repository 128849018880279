import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';
import {AuthService} from '../../service/auth.service';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {$} from 'jquery';
import {DomSanitizer} from '@angular/platform-browser';
import {SharedService} from 'src/app/service/shared.service';


@Component({
    selector: 'app-coty-design-connect',
    templateUrl: './coty-design-connect.component.html',
    styleUrls: ['./coty-design-connect.component.css']
})
export class CotyDesignConnectComponent implements OnInit {
    aid: any;
    tokenvalue: string;
    api = environment.apiEndpoint;
    imgurl = environment.imageurl;
    allvalues: any;
    image_value: string;
    color: string;
    title: string;
    content: any;
    brand_name: any;
    main_font: string;
    second_font: string;
    profile_datavalue: any;
    servicename: any;
    title_color: string;
    line_color: string;
    main_color: any = "#333 ";
    main_title_color: any = "#fff";
    start_img: string;
    back_btn_color: any;
    end_title: any;
    end_content: any;
    end_img: string;
    service_complete: any;
    fk_brand_id: string;
    electrolux_white_font: boolean = false;

    constructor(private authservice: AuthService, private ar: ActivatedRoute, private router: Router, private httpclient: HttpClient, private domSanitizer: DomSanitizer, private shareservice: SharedService) {
        this.tokenvalue = localStorage.getItem('auth-token');
    }

    ngOnInit() {
        this.authservice.hideshowheader(false);

        this.ar.params.subscribe((params) => {
            this.aid = params['slug'];
            //if library clicked
            if (localStorage.getItem('from_library')) {
                localStorage.removeItem('from_library');
                this.redirect_landingpage(this.aid, 'redirect')
            }

            localStorage.setItem('library', this.aid)
            this.getalldetails_aboutlandingpage(this.aid);
            this.getprofiledetails(this.tokenvalue)
            this.shareservice.servicename.subscribe(v => {
                if (v != '') {
                    this.servicename = v
                }
            })
        });


        this.fk_brand_id = localStorage.getItem('fk_brand_id');
    }

    getalldetails_aboutlandingpage(slug) {
        this.tokenvalue = localStorage.getItem('auth-token');
        this.httpclient.get(this.api + "auth/landingpage_details?token=" + this.tokenvalue + "&slug=" + slug).subscribe((data: alldetailsaboutpage) => {
            this.image_value = data.image;
            this.color = data.color;
            this.brand_name = data.brand_name
            this.title = data.title;
            this.end_title = data.end_title;
            this.service_complete = data.service_complete
            if (this.service_complete == 1) {
                this.redirect_landingpage(this.aid, 'no')
            }
            console.log(this.brand_name)
            if (this.brand_name == 'park') {
                this.main_font = "SilkaMono";
                this.second_font = "'Roboto', sans-serif";
                this.main_title_color = "#fff" + "!important";
                this.title_color = "#fff" + "!important";
                this.line_color = "#fff" + "!important";
                this.main_color = "#333 !important";
                this.start_img = "#cfe4f6" + ' ' + "url('./assets/icon/start-program.svg')" + '' + '100% 12px no-repeat;';
                this.end_img = "#cfe4f6" + ' ' + "url('./assets/img/DoneSymbol.svg')" + '' + '0% 0px no-repeat;';
                this.back_btn_color = "#01759e";

            }
            if (this.brand_name == 'grow') {
                this.main_font = "'Museo W01 500', HelveticaNeue, Helvetica";
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                this.main_title_color = "#fff" + "!important";
                this.title_color = "#fff" + "!important";
                this.line_color = "#fff" + "!important";
                this.main_color = "#333 !important";
                this.start_img = "#cfe4f6" + ' ' + "url('./assets/icon/start-program.svg')" + '' + '100% 12px no-repeat;';
                this.end_img = "#cfe4f6" + ' ' + "url('./assets/img/DoneSymbol.svg')" + '' + '0% 0px no-repeat;';
                this.back_btn_color = "#01759e";

            }
            if (this.brand_name == 'coty') {
                this.main_font = "'CotySans-Bold'";
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                this.main_title_color = "#fff" + "!important";
                this.title_color = "#fff" + "!important";
                this.line_color = "#fff" + "!important";
                this.main_color = "#fff !important";
                this.start_img = "#736f6e" + ' ' + "url('./assets/icon/start-program.svg')" + '' + '100% -38px no-repeat;';
                this.end_img = "#736f6e" + ' ' + "url('./assets/img/DoneSymbol.svg')" + '' + '0% 0px no-repeat;';
                this.back_btn_color = "#fff";
            }
            if (this.brand_name == 'lego') {
                this.main_font = "CeraPro, Arial, Helvetica, sans-serif"
                this.second_font = "CeraPro, 'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                this.main_title_color = "#fff" + "!important";
                this.title_color = "#fff" + "!important";
                this.line_color = "#f0f0f0" + "!important";
                this.main_color = "#333 !important";
                this.start_img = "#f0f0f0" + ' ' + "url('./assets/icon/start-program.svg')" + '' + '100% -87px no-repeat;';
                this.end_img = "#f0f0f0" + ' ' + "url('./assets/img/DoneSymbol.svg')" + '' + '0% 0px no-repeat;';
                this.back_btn_color = "#971814";
            }
            if (this.brand_name == "raymond") {
                this.main_font = "'Airstrip-Four', Arial, HelveticaNeue, Helvetica"
                this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                this.main_title_color = "#fff" + "!important";
                this.title_color = "#fff" + "!important";
                this.line_color = "#F8BFD1" + "!important";
                this.main_color = "#333 !important";
                this.start_img = "#f8Bfd1" + ' ' + "url('./assets/icon/start-program.svg')" + '' + '100% -188px no-repeat;';
                this.end_img = "#f8Bfd1" + ' ' + "url('./assets/img/DoneSymbol.svg')" + '' + '0% 0px no-repeat;';
                this.back_btn_color = "#C42455";
            }
            if (this.brand_name == "gsk") {
                this.main_font = "'Arial', HelveticaNeue, Helvetica, Geneva, sans-serif"
                this.second_font = "'Arial', HelveticaNeue, Helvetica, Geneva, sans-serif";
                this.main_title_color = "#7c7571" + "!important";
                this.title_color = "#000" + "!important";
                this.line_color = "#ec6d2c" + "!important";
                this.main_color = "#333 !important";
                this.start_img = "#ada7a3" + ' ' + "url('./assets/icon/start-program.svg')" + '' + '100% -38px no-repeat;';
                this.end_img = "#ada7a3" + ' ' + "url('./assets/img/DoneSymbol.svg')" + '' + '0% 0px no-repeat;';
                this.back_btn_color = "#fff";
            }
            if (this.brand_name == "drager") {
                this.main_font = "'draeger'"
                // this.second_font="'arial'";
                this.second_font = "'draeger2'";

                // this.main_title_color="#08035a"+"!important";
                this.main_title_color = "#0f4da2 !important";

                this.title_color = "#000" + "!important";
                this.line_color = "#fff" + "!important";
                // this.main_color="#000099  !important";
                this.main_color = "#333  !important";

                this.start_img = "#ada7a3" + ' ' + "url('./assets/icon/start-program.svg')" + '' + '100% -38px no-repeat;';
                this.end_img = "#d2e8f8" + ' ' + "url('./assets/img/DoneSymbol.svg')" + '' + '0% 0px no-repeat;';
                this.back_btn_color = "#fff";
            }
            if (this.brand_name == 'electrolux') {
                this.main_font = "'electrolux-sans-semibold'";
                this.second_font = "'electrolux-regular'";
                this.main_title_color = "#fff" + "!important";
                this.title_color = "#fff" + "!important";
                this.line_color = "#fff" + "!important";
                this.main_color = "#333 !important";
                this.start_img = "#cfe4f6" + ' ' + "url('./assets/icon/start-program.svg')" + '' + '100% 12px no-repeat;';
                this.end_img = "#cfe4f6" + ' ' + "url('./assets/img/DoneSymbol.svg')" + '' + '0% 0px no-repeat;';
                this.back_btn_color = this.color;
                if (this.color != 'mustard') {
                    this.electrolux_white_font = true
                } else {
                    this.electrolux_white_font = false
                }

            }

            this.content = this.domSanitizer.bypassSecurityTrustHtml(data.content + '<style>h1.main-title{color:' + this.main_title_color + '} h2.title {color:' + this.main_title_color + '} div.line {border-top:1px solid ' + this.line_color + '} #content h1,h2,h3,h4,h5,h6{font-family:' + this.main_font + '}  #content ul,li,p,strong{font-family:' + this.second_font + '} #content ul,li,p,strong {color:' + this.main_color + '}  button.startbtn {background:' + this.start_img + '} button.startbtn {color:' + this.back_btn_color + '!important }  #content div,p {font-size:22px !important}</style>');
            this.end_content = this.domSanitizer.bypassSecurityTrustHtml(data.end_content + '<style>h1.main-title{color:' + this.main_title_color + '} h2.title {color:' + this.main_title_color + '} div.line {border-top:1px solid ' + this.line_color + '} #content h1,h2,h3,h4,h5,h6{font-family:' + this.main_font + '}  #content ul,li,p,strong{font-family:' + this.second_font + '} #content ul,li,p,strong {color:' + this.main_color + '}  button.donebtn {background:' + this.end_img + '} button.donebtn {color:' + this.back_btn_color + ' !important } button.donebtn {padding:6px 28px 5px 45px} #endcontent div,p {font-size:22px !important} </style>')
        });
    }

    getprofiledetails(tokenvalue) {
        this.httpclient.get(this.api + "auth/profiledata?token=" + tokenvalue).subscribe((data: profile) => {
            this.profile_datavalue = data.profile;
        }, (error) => {
            console.log(error);
        })
    }

    redirect_landingpage(slug, next) {
        this.tokenvalue = localStorage.getItem('auth-token');
        this.httpclient.get(this.api + "auth/fast_flow_data?token=" + this.tokenvalue + "&slug=" + slug).subscribe((data: allvalues_data) => {
            console.log(this.allvalues);
            this.allvalues = data.redirect;
            if (this.service_complete == 1) {
                this.shareservice.serviceDoneFastFlow(data.redirect)
            }
            let check = data.redirect.substr(0, data.redirect.indexOf('/'))
            if (check == 'livebook') {
                if (data.modules.length == 1) {
                    localStorage.setItem('modulestatus', data.modules['0'].module_status)
                    localStorage.setItem('one_module', '1');
                } else {
                    localStorage.setItem('one_module', '0');
                }
            }
            if (check == 'library') {
                if (data.modules.length == 1) {
                    localStorage.setItem('modulestatus', data.modules['0'].module_status)
                    localStorage.setItem('one_module', '1');
                } else {
                    localStorage.setItem('one_module', '0');
                }
            }

            if (next == 'redirect') {
                this.router.navigate([this.allvalues]);
            }
        });
    }

}

export interface allvalues_data {
    redirect: any;
    modules: any[];
}

export interface alldetailsaboutpage {
    image: string;
    color: string;
    title: string;
    content: string;
    brand_name: any;
    end_title?: any;
    end_content?: any;
    service_complete?: any;
}

export interface profile {
    profile: any[];
}

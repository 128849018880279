import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
// import { SwiperComponent } from 'angular2-useful-swiper';
import {HttpClient, HttpResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {
    SwiperComponent, SwiperConfigInterface,
    SwiperScrollbarInterface, SwiperPaginationInterface
} from 'ngx-swiper-wrapper';
import {AuthService} from '../service/auth.service';
import {environment} from '../../environments/environment';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {SharedService, roleModuleChapterNameInterface, user_accessInterface} from '../service/shared.service';

import {DomSanitizer} from '@angular/platform-browser';
import $ from 'jquery';
import {BreakpointObserver} from '@angular/cdk/layout';

@Component({
    selector: 'app-design-management',
    templateUrl: './design-management.component.html',
    styleUrls: ['./design-management.component.css']
})
export class DesignManagementComponent implements OnInit {
    tokenvalue: string;
    api = environment.apiEndpoint;
    imgurl = environment.imageurl;
    aid: any;
    counter: any;
    page: any[] = [];
    pagedata: any[] = [];
    main_color: any[];
    main_brand: any;
    dm: string;
    page_id: any;
    next_page: any = 0;
    active_index = 0;
    mymenu: any[];
    module_name: any;
    chapter_name: any;
    role: any;
    main_hexacolor: any[];
    title_color: any;
    active_bullet: any;
    questionPage: any = true;
    page_changed: any;
    activeat: any;
    serviceslug: string;
    loading: boolean = false;
    learninggoals: any[] = [];
    userinlg: any[] = [];
    result_indu_team: any = '1';
    assignment_name: any;
    uploadparamdata: any = {};
    AllowedFile = ['pdf', 'jpg', 'png', 'svg', 'doc', 'ppt', 'xls', 'mp4'];
    file: File;
    allow_upload = false;
    file_name: string;
    uploadpdf_data: any = [];
    deleteConfirmShow: boolean = false;
    next_module: any = 0;
    service: any;
    upload_message: string;
    nolearninggoals: boolean = false;
    edit_note = false;
    number_color: string = 'white';


    //user access
    useraccess: user_accessInterface = {
        isaddnote: "",
        isaddreflection: "",
        islivebookserviceaccess: "",
        isprofnotesshowlivebook: "",
        isprogram: "",
        isroleid: "",
        isuploadassignments: "",
        isworkshop: "",
        view_notes: ""
    }

    //add notes and all
    notesreq: boolean = false;
    nt_privacy_note = "1"
    notes = "1"
    nt_description = "";
    addnotefile: any;
    addnote_filename: any;
    allow_upload_notes = false;
    add_notes_form = true
    allnotes_data: any[];
    check_user: any = "all"
    user_slug: string;
    showloading = true;
    notes_value: any = '1';
    allow_upload_notes_reaction = false;

    reactionindex: any;
    reaction_text: any = "";
    reaction_file_array = []
    edit_id_value: any;
    edit_text_value: any;
    edit_file_value: any;
    edit_notetype_value: any;
    note_or_reaction: any;

    edit_file: any;
    edit_file_name: string;
    edit_allow_upload = false;
    allnote_count: any;
    mynote_count: any;
    note_modal_open: boolean = false;
    main_css: any;
    main_font: string;
    second_font: string;
    main_service_notes: any;
    enlarge_img: any;
    imageInterval: any;
    service_id: any;
    module_id: any;
    cluster_id: any;
    next_text: any;
    mtype: string;
    service_enforce: any = 0;
    chapter_comp_value: any;
    hedingfontcolor: pagedatadetails;

    show_assignment: any;
    allow_upload_assign: any;
    upload_access: any
    show_notes: any;
    note_access: any;
    notification: any;
    exit_redirect: string;
    service_enforce2: string;
    module_id2: string;
    pageoverview_name: any;
    check_enforce2: string;
    user_actual_image: string;
    image2: any;
    single_user_data: any;
    imagetwouploaded: boolean = false;
    token: string;
    slug: any;
    u_role: any;
    image3: string;
    profilepic: string;
    chapter_id: any;
    fk_brand_id: string;
    overview_page: any;
    chapter_change: boolean;

    constructor(private el: ElementRef, public authservice: AuthService, private ar: ActivatedRoute, private httpclient: HttpClient, private shareservice: SharedService, private domSanitizer: DomSanitizer, private router: Router, public breakpointObserver: BreakpointObserver) {
        this.dm = localStorage.getItem('livebook');
        this.serviceslug = localStorage.getItem('library');
        this.tokenvalue = localStorage.getItem('auth-token');
        this.user_actual_image = localStorage.getItem('user_actual_image');
        this.image2 = localStorage.getItem('overview_image');
        console.log(this.image2);
        if (this.image2 == null) {
            this.profilepic = './assets/img/profile_blank.png';
        } else {
            this.profilepic = this.imgurl + 'storage/profile_img/resize_' + this.image2;

        }
        this.httpclient.get(this.api + 'auth/access_rights?token=' + this.tokenvalue + "&slug=" + this.serviceslug).subscribe((data: userroalaccess) => {
            let user_access = data.role;
            this.useraccess.isaddnote = user_access['0'].add_notes
            this.useraccess.isaddreflection = user_access['0'].add_reflections
            this.useraccess.islivebookserviceaccess = user_access['0'].livebook_service_access
            this.useraccess.isprofnotesshowlivebook = user_access['0'].prof_notes_show_livebook
            this.useraccess.isprogram = user_access['0'].program         //program like tutor student and all
            this.useraccess.isroleid = user_access['0'].rl_id            // user roals id 1 2 3 ...
            this.useraccess.isuploadassignments = user_access['0'].upload_assignments
            this.useraccess.isworkshop = user_access['0'].workshop        // workshop name
            this.useraccess.view_notes = user_access['0'].view_notes        // to view note or not
            if (this.useraccess.isprogram == 'Hidden') {
                this.nt_privacy_note = "2"
            }
        })

    }

    @ViewChild('usefulSwiper') usefulSwiper: SwiperComponent;
    config: SwiperConfigInterface = {};

    ngOnInit() {
        this.breakpointObserver
            .observe(['(min-width:1024px) and (max-width:1920px)'])
            .subscribe((state) => {
                if (state.matches) {
                    $('#modulechaptername').removeClass('maxwidth-1920');
                    $('#notesdata').removeClass('notes-data-1920');
                    $('#notesdata').addClass('w-100')
                } else {
                    $('#modulechaptername').addClass('maxwidth-1920');
                    $('#notesdata').addClass('notes-data-1920')
                    $('#notesdata').removeClass('w-100')

                }
            });
        this.breakpointObserver
            .observe(['(min-width:768px) and (max-width:1023px)'])
            .subscribe((state) => {
                if (state.matches) {
                    $('#modulechaptername').removeClass('maxwidth-1920');
                    $('#notesdata').removeClass('notes-data-1920');
                    $('#notesdata').addClass('w-100')
                } else {


                }
            });


        this.fk_brand_id = localStorage.getItem("fk_brand_id");

        this.token = localStorage.getItem('auth-token');
        this.ar.params.subscribe((params) => {

            this.slug = params['slug'];
            // this.profiledata(this.token,this.slug);
        })
        this.authservice.hideshowheader(true);

        this.exit_redirect = localStorage.getItem('redirect')
        this.service_enforce2 = localStorage.getItem('service_enforce');
        this.module_id2 = localStorage.getItem('module_id');


        if (localStorage.getItem('service_enforce') == '1') {
            this.service_enforce = localStorage.getItem('service_enforce');

        }

        this.shareservice.enlarge_image.subscribe(v => {
            this.enlarge_img = v
            setInterval(() => {
                this.imageInterval = this.shareservice.enlarge_image.subscribe(v => {
                    this.enlarge_img = v

                })
                if (this.enlarge_img != null) {
                    clearInterval(this.imageInterval)
                }
            }, 100)
        })


        this.active_index = 0
        this.pagesdetails();

    }


    pagesdetails() {
        this.ar.params.subscribe((params) => {
            this.service_id = params['service_id'];

            this.module_id = params['module_id'];
            this.module_id2 = params['module_id'];
            this.cluster_id = params['cluster_id'];
            this.aid = params['chapter_slug'];
            this.activeat = params['active_at'];
            this.mtype = params['mtype']
            this.pagedata = []
            this.getRolesModuleChap();


            // this.config.autoHeight = true;
            // this.config.roundLengths = true;

            // this.config.setWrapperSize = true;

            this.activeat = params['active_at']

            // duplication (service_id and service_slug) caused by unification of the input for Service Permission Middleware in the backend
            this.httpclient.get(this.api + "auth/pagedata?token=" + this.tokenvalue + "&service_id=" + this.service_id + "&module_id=" + this.module_id + "&cluster_id=" + this.cluster_id + "&slug=" + this.aid + "&active_at=" + this.activeat + "&mtype=" + this.mtype + "&service_slug=" + this.serviceslug).subscribe((data: pagedatadetails) => {
                this.main_color = data.color;
                this.main_brand = data.brand;
                if (this.chapter_change) {
                    this.active_index = 0;
                } else {
                    this.active_index = data.active_at;
                }
                this.main_hexacolor = data.hexacode;
                this.hedingfontcolor = data.font_code;
                this.title_color = data.title_code;
                // this.active_bullet = this.domSanitizer.bypassSecurityTrustHtml('<style> .cpage .swiper-pagination-bullet-active {background:' + this.hedingfontcolor + '!important' + '} </style>');
                this.counter = data.counter;
                this.page = data.page;
                this.pagedata = data.pagedata;
                this.main_service_notes = data.notes_allowed
                //this code should be on if white top border needed
                // if (this.fk_brand_id == '6') {
                // $("#library").css("top", "42px")
                // $('#modulechaptername').css("top", "42px")

                // setTimeout(() => {
                //   if (localStorage.getItem('keyboard_arrow') == 'keyboard_arrow_down') {
                //     $('#chaptersection').css("padding-top", "87px");
                //     $("#updarrow").css("top", "45px");
                //   } else {
                //     $("#updarrow").css("top", "87px")
                //     $('#chaptersection').css("padding-top", "132px")
                //   }

                // }, 500);


                // }else {

                setTimeout(() => {
                    if (localStorage.getItem('keyboard_arrow') == 'keyboard_arrow_down') {
                        $('#chaptersection').removeClass('tp-90');
                        $('#chaptersection').addClass('tp-45');
                    }
                }, 500);
                // }

                if (localStorage.getItem('library')) {

                } else {
                    let service_slug = data.service
                    localStorage.setItem('library', service_slug)
                }

                //for question because if question page is first then page_change is undefined so we are giving firt id and for more check in final evaluation ts ng onChnage
                this.page_changed = this.pagedata['0'].id
                // end for question because if question page is first then page_change is undefined so we are giving firt id and for more check in final evaluation ts ng onChnage

                if (this.pagedata.length !== 0) {
                    this.page_id = this.pagedata[0].id
                }
                this.next_page = data.next_page
                this.next_module = data.next_module
                this.next_text = data.next_text;
                this.service = data.service


                var menu = this.page;
                // var menu = this.page;
                this.mymenu = this.page;


                this.config = {
                    /**used angular2-usefull-swiper **/ //
                    a11y: true,
                    direction: 'horizontal',
                    slidesPerView: 1,
                    setWrapperSize: true,
                    roundLengths: true,
                    keyboard: true,
                    mousewheel: false,
                    scrollbar: false,
                    navigation: false,
                    zoom: false,
                    preloadImages: true,
                    updateOnImagesReady: true,
                    // autoHeight:true,
                    simulateTouch: false,   // if uncomment then on swipe slider will not change
                    pagination: {
                        el: '.cpage',
                        clickable: true,
                        hideOnClick: false,
                        renderBullet: function (index, className) {
                            return '<span class="' + className + '">' + (menu[index]) + '</span>';
                        },
                    },

                };

                //to set dynamic height of slider so no empty spaces will be there
                $(document).on(function ()    //on
                {
                    var mySwiper = $('.swiper-container').swiper({
                        onSlideChangeStart: function () {
                            $('.swiper-container').height($(mySwiper.activeSlide()).height())

                        }
                    })

                })

                this.userPageTracking();

                if (data.next_module == '1') {
                    this.module_id = data.next_array.fk_module_id
                }
                if (data.next_array) {
                    this.cluster_id = data.next_array.fk_cluster_id
                }
                if (data.next_array) {
                    this.chapter_id = data.next_array.fk_chapter_id
                }

                if (this.main_brand == 'park') {
                    this.active_bullet = this.domSanitizer.bypassSecurityTrustHtml('<style> .cpage .swiper-pagination-bullet-active {background:' + this.hedingfontcolor + '!important' + '} </style>');
                    this.main_font = "SilkaMono";
                    this.second_font = "'Roboto', sans-serif";
                    this.number_color = 'black';
                }
                if (this.main_brand == 'grow') {
                    this.active_bullet = this.domSanitizer.bypassSecurityTrustHtml('<style> .cpage .swiper-pagination-bullet-active {background:' + this.hedingfontcolor + '!important' + '} </style>');
                    this.main_font = "'Museo W01 500', HelveticaNeue, Helvetica";
                    this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                }
                if (this.main_brand == 'coty') {
                    this.active_bullet = this.domSanitizer.bypassSecurityTrustHtml('<style> .cpage .swiper-pagination-bullet-active {background:' + this.hedingfontcolor + '!important' + '} </style>');
                    this.main_font = "'CotySans-Bold'";
                    this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                }
                if (this.main_brand == 'lego') {
                    this.active_bullet = this.domSanitizer.bypassSecurityTrustHtml('<style> .cpage .swiper-pagination-bullet-active {background:' + this.hedingfontcolor + '!important' + '} </style>');
                    this.main_font = "CeraPro, Arial, Helvetica, sans-serif"
                    this.second_font = "CeraPro, 'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                }
                if (this.main_brand == "raymond") {
                    this.active_bullet = this.domSanitizer.bypassSecurityTrustHtml('<style> .cpage .swiper-pagination-bullet-active {background:' + this.hedingfontcolor + '!important' + '} </style>');
                    this.main_font = "'Airstrip-Four', Arial, HelveticaNeue, Helvetica"
                    this.second_font = "'Calibri W01 Regular 904604', Arial, HelveticaNeue, Helvetica, Geneva, sans-serif";
                }
                if (this.main_brand == "gsk") {
                    this.active_bullet = this.domSanitizer.bypassSecurityTrustHtml('<style> .cpage .swiper-pagination-bullet-active {background:' + this.hedingfontcolor + '!important' + '} </style>');
                    this.main_font = "'Arial', HelveticaNeue, Helvetica, Geneva, sans-serif"
                    this.second_font = "'Arial', HelveticaNeue, Helvetica, Geneva, sans-serif";
                }
                if (this.main_brand == 'drager') {
                    this.active_bullet = this.domSanitizer.bypassSecurityTrustHtml('<style> .cpage .swiper-pagination-bullet-active {background:' + this.hedingfontcolor + '!important' + '} </style>');
                    this.main_font = "'draeger'"
                    this.second_font = "'draeger2'"
                }
                if (this.main_brand == 'electrolux') {
                    this.active_bullet = this.domSanitizer.bypassSecurityTrustHtml('<style> .cpage .swiper-pagination-bullet-active {background:' + this.main_hexacolor + '!important' + '} </style>');
                    this.main_font = "'electrolux-sans-semibold'"
                    this.second_font = "'electrolux-regular'";
                }
                this.main_css = this.domSanitizer.bypassSecurityTrustHtml('<style>#notesmodal,#resultmodal h1,h2,h3,h4,h5,h6{font-family:' + this.main_font + '} #notesmodal,#resultmodal .emphasized:{' + this.main_color + '} #notesmodal,#resultmodal ul,li,p,strong{font-family:' + this.second_font + '} ul.key-questions li span.number {color: ' + this.number_color + '} </style>')
            }, (error) => {
                console.log(error);
            });
        });


    }


    onIndexChange($event) {
        this.upload_message = ""
        this.file_name = ""
        this.allow_upload = false;
        this.nolearninggoals = false;
        this.uploadpdf_data = []
        this.deleteConfirmShow = false;
        this.allnotes_data = []
        this.reaction_file_array = []
        this.note_modal_open = false;
        this.showloading = true;
        this.show_assignment = false;
        this.show_notes = '1'

        this.active_index = $event;
        this.page_changed = this.pagedata[$event].id
        this.page_id = this.pagedata[$event].id

        if (this.pagedata[$event].page_type !== 'questions') {
            this.userPageTracking();
        }
        ``


    }

    callPageChangeAgain(v) {
        if (v) {
            this.userPageTracking()
        }
    }

    userPageTracking() {

        this.httpclient.get(this.api + "auth/userpagetracking?token=" + this.tokenvalue + "&slug=" + this.aid + "&id=" + this.page_id + "&service_id=" + this.service_id + "&module_id=" + this.module_id + "&cluster_id=" + this.cluster_id).subscribe((data: userpagetrack) => {
            console.log(data)
            console.log(data.status)
        })
    }

    getRolesModuleChap() {
        this.shareservice.getRolesModuleChapterName(this.tokenvalue, "0", this.aid).subscribe((data: roleModuleChapterNameInterface) => {
            console.log(data)
            this.module_name = data.module_name;
            // console.log( this.module_name);
            localStorage.setItem('module_name', this.module_name);
            this.chapter_name = data.chapter_name;
            // this.pageoverview_name = data.pageoverview_name;
            this.role = data.roleid

        })
    }

    nextChapter() {
        this.edit_note = false;
        this.nt_privacy_note = '1'
        this.show_notes == '1'
        this.show_assignment == false;


        this.active_index = 0;
        this.chapter_change = true;
        this.upload_message = ""
        this.file_name = ""
        this.allow_upload = false;
        this.nolearninggoals = false;
        this.uploadpdf_data = []
        this.deleteConfirmShow = false;
        this.allnotes_data = []
        this.reaction_file_array = []
        this.note_modal_open = false;
        this.showloading = true;


        this.forEnforceCheck()


    }

    resultsData(v) {
        console.log(v.aid)
        this.result_indu_team = v.type
        this.assignment_name = v.aname
        this.httpclient.get(this.shareservice.api + "auth/assignment_result_data?token=" + this.tokenvalue + "&service_slug=" + this.serviceslug + "&assignment_id=" + v.aid + "&page_id=" + v.pid + "&type=" + v.type).subscribe((data: assignment) => {
            this.learninggoals = data.assignments['0'].learning_goaldata
            if (this.learninggoals.length == 0) {
                this.nolearninggoals = true;
            }
            this.userinlg = data.users

        })
    }

    uploadData(v) {
        this.uploadparamdata = v
        this.assignment_name = v.aname
        let pid = ""
        if (this.uploadparamdata.type == "1") {
            pid = ""
        } else {

            pid = this.uploadparamdata.tid
        }

        this.httpclient.get(this.shareservice.api + "auth/upload_assignment_data?token=" + this.tokenvalue + '&assignment_type=' + this.uploadparamdata.type + "&assignment_id=" + this.uploadparamdata.aid + "&service_slug=" + this.serviceslug + "&profile_id=" + pid).subscribe((data: getpdf) => {
            this.uploadpdf_data = data.data
        })
    }

    fileChange($event) {

        var file: File = $event.target.files[0];
        if (file) {
            if (!this.validateFile(file)) {
                alert("Unsupported File format");
                return false;
            }
            if (file.size > 5242880) {
                alert("Max. File size: 5MB");
                return false;
            }
            this.file = file
            this.file_name = file.name
            this.allow_upload = true
        }


    }

    validateFile(file: File) {
        let extension = file.name.split('.').pop().toLowerCase();

        return this.AllowedFile.indexOf(extension) > -1;
    }

    uploadFile() {
        if (this.allow_upload) {
            this.loading = true
            const fd = new FormData()
            fd.append('service_slug', this.serviceslug)
            fd.append('assignment_type', this.uploadparamdata.type)
            fd.append('assignment_id', this.uploadparamdata.aid)
            fd.append('upload_file', this.file, this.file_name)
            if (this.uploadparamdata.type == "1") {
                fd.append('profile_id', "")
            } else {

                fd.append('profile_id', this.uploadparamdata.tid)
            }

            this.httpclient.post(this.shareservice.api + 'auth/upload_assignment_result?token=' + this.tokenvalue, fd).subscribe(data => {
                this.loading = false;
                this.uploadData(this.uploadparamdata)
                this.upload_message = ""
                this.allow_upload = false;
            }, (error) => {
                this.loading = false;
                alert("Error in uploading file")
            })
        } else {
            this.upload_message = "Please upload a PDF"
        }
    }

    confirmDeletePdf() {
        this.deleteConfirmShow = true;
    }

    dismissDeletePdf() {
        this.deleteConfirmShow = false;
    }

    deletePdf() {
        let pid = ""
        if (this.uploadparamdata.type == "1") {
            pid = ""
        } else {

            pid = this.uploadparamdata.tid
        }
        this.httpclient.get(this.shareservice.api + "auth/delete_assignment_result?token=" + this.tokenvalue + '&assignment_type=' + this.uploadparamdata.type + "&assignment_id=" + this.uploadparamdata.aid + "&service_slug=" + this.serviceslug + "&profile_id=" + pid).subscribe((data) => {
            this.uploadpdf_data = [];
            this.file_name = "";
            this.deleteConfirmShow = false;
        })

    }

    notesData(v) {
        this.page_id = v
        this.reaction_file_array = []
        this.note_modal_open = true;
        if (this.notes_value == "1") {
            this.check_user = "all"
        } else {
            this.check_user = this.tokenvalue;
        }
        if (this.showloading) {
            // this.loading=true;
        }
        if (this.useraccess.isprogram == 'Hidden') {
            this.nt_privacy_note = "2"
            $('#forme').attr('checked', true);
        }
        this.httpclient.get(this.shareservice.api + "auth/view_note?token=" + this.tokenvalue + "&page_id=" + v + "&user_id=" + this.check_user + "&nt_privacy_note=" + this.notes_value).subscribe((data: getnotes) => {
            console.log(data)
            this.allnotes_data = data.data
            this.allnote_count = data.all_notes;
            this.mynote_count = data.my_notes
            this.loading = false;
            setTimeout(() => {
                if (this.reaction_text == "") {
                    if (this.reactionindex >= 0) {
                        $('.notereacerror' + this.reactionindex).remove()
                        $('#notereacerror' + this.reactionindex).append('<p class=\"preft' + this.reactionindex + '\" style="color:red;">A text is required.</p>')
                    }
                }
                this.httpclient.get(this.shareservice.api + "auth/user_notification?token=" + this.tokenvalue + "&fk_page_id=" + this.page_id).subscribe((data) => {
                    console.log(data)
                })
            }, 100)


        }, (error) => {
            this.loading = false;
        })
    }

    notesTab(v)       //  when click on all notes and my notes
    {
        this.notes_value = v
        this.showloading = false
        this.notesData(this.page_id)
    }

    addNoteFiles($event) {
        var file: File = $event.target.files[0];
        console.log(file)
        console.log(file.size)
        console.log(file.type)


        if (!this.validateFile(file)) {
            alert("Unsupported File format");
            return false;

            if (file.size > 5242880) {
                alert("Max. File size: 5MB");
                return false;

            }
        }
        // if (file.size > 5242880) {
        //   alert("Max. File size: 5MB");
        //  return false;

        // }

        this.allow_upload_notes = true
        this.addnotefile = $event.target.files[0]
        this.addnote_filename = file.name


    }

    addNoteReactionFiles($event, index) {
        var file: File = $event.target.files[0];

        if (file) {
            console.log(file)
            this.allow_upload_notes_reaction = true

            if (this.reaction_file_array.length != 0) {
                for (let i = 0; i < this.reaction_file_array.length; i++) {
                    console.log(this.reaction_file_array[i].index)
                    console.log(index)
                    if (this.reaction_file_array[i].index == index) {
                        this.reaction_file_array[i].file = $event.target.files[0]
                        this.reaction_file_array[i].file_name = file.name
                        console.log('index', this.reaction_file_array)
                        return false;
                    }
                }
                this.reaction_file_array.push({index: index, file: $event.target.files[0], file_name: file.name})
            } else {
                this.reaction_file_array.push({index: index, file: $event.target.files[0], file_name: file.name})
            }

            console.log(this.reaction_file_array)


        }
    }

    addNotes() {

        if (this.nt_description == "") {
            this.notesreq = true;
        } else {
            this.notesreq = false;
        }

        if (!this.notesreq) {

            this.loading = true;
            const fd = new FormData()
            fd.append('nt_privacy_note', this.nt_privacy_note)
            fd.append('nt_description', this.nt_description)
            fd.append('nt_usertype', this.useraccess.isaddnote)
            fd.append('service_slug', this.serviceslug)
            fd.append('chapter_slug', this.aid)
            if (this.allow_upload_notes) {
                fd.append('nt_attachment', this.addnotefile, this.addnote_filename)
            }
            this.add_notes_form = false;
            this.httpclient.post(this.shareservice.api + "auth/insert_note?token=" + this.tokenvalue + "&page_id=" + this.page_id, fd).subscribe((data) => {
                console.log(data)
                this.loading = false;
                this.add_notes_form = true;
                this.showloading = false;
                this.notesData(this.page_id)
                this.notesreq = false;
                this.allow_upload_notes = false;
                if (this.useraccess.isprogram == 'Hidden') {
                    this.nt_privacy_note = "2"
                } else {
                    this.nt_privacy_note = '1'
                }
                this.nt_description = ""
            }, (error) => {
                this.loading = false;
                this.add_notes_form = true;
            })
        }

    }

    addNoteReaction(i, nid, ri) {

        this.reactionindex = i
        if ($('#notereactext' + i).val() != '') {

            this.showloading = false;
            this.reaction_text = $('#notereactext' + i).val()
            const fd = new FormData()

            if (this.allow_upload_notes_reaction) {
                for (let j = 0; j < this.reaction_file_array.length; j++) {
                    if (this.reaction_file_array[j].index == i) {
                        console.log(this.reaction_file_array[j].file_name)
                        fd.append('rf_attachment', this.reaction_file_array[j].file, this.reaction_file_array[j].file_name)
                    }
                }

            }
            $('#reacion_btn' + i).attr("disabled", true)
            this.httpclient.post(this.shareservice.api + "auth/insert_note_reaction?token=" + this.tokenvalue + "&rf_noteid=" + nid + "&rf_description=" + this.reaction_text + "&rf_usertype=" + this.useraccess.isaddnote + "&fk_page_id=" + this.page_id, fd).subscribe((data) => {
                console.log(data)
                this.allow_upload_notes_reaction = false
                //  this.reaction_text=""
                $('#reacion_btn' + i).removeAttr("disabled")
                this.notesData(this.page_id)
            }, (error) => {
                $('#reacion_btn' + i).removeAttr("disabled")
            })
        } else {
            this.notesData(this.page_id)
            // $('.notereacerror'+i).remove()
            // $('#notereacerror'+i).append('<p class=\"preft' +i+'\" style="color:red;">Your reaction is required.</p>')
        }
    }

    //notes modal
    showHideEditNotes(check, id, text, file, notetype) {
        this.note_or_reaction = check      // check 1 == note and 2 ==reaction
        this.edit_note = !this.edit_note
        this.edit_id_value = id;
        this.edit_text_value = text;
        this.edit_file_value = file;
        this.edit_notetype_value = notetype;
    }

    editNoteReactionFile($event) {
        var file: File = $event.target.files[0];
        if (file) {
            if (!this.validateFile(file)) {
                alert("Unsupported File format");
                return false;
            }
            if (file.size > 5242880) {
                alert("Max. File size: 5MB");
                return false;
            }
            this.edit_file = file
            this.edit_file_name = file.name
            this.edit_allow_upload = true
        }

    }

    editNotesReaction() {
        console.log(this.edit_file)
        const fd = new FormData()
        if (this.note_or_reaction == '1') {
            fd.append('nt_privacy_note', this.edit_notetype_value)
            fd.append('nt_description', this.edit_text_value)
            fd.append('nt_id', this.edit_id_value)
            if (this.edit_allow_upload) {
                fd.append('nt_attachment', this.edit_file, this.edit_file_name)
            } else {
                fd.append('old_nt_attachment', this.edit_file_value)
            }
            this.httpclient.post(this.shareservice.api + 'auth/update_note?token=' + this.tokenvalue + "&page_id=" + this.page_id, fd).subscribe((data) => {
                console.log(data)
                this.edit_note = false;
                this.notesData(this.page_id)
            })
        }
        if (this.note_or_reaction == "2") {
            fd.append('rf_description', this.edit_text_value)
            fd.append('rf_id', this.edit_id_value)
            if (this.edit_allow_upload) {
                fd.append('rf_attachment', this.edit_file, this.edit_file_name)
            } else {
                fd.append('old_rf_attachment', this.edit_file_value)
            }
            this.httpclient.post(this.shareservice.api + "auth/update_note_reaction?token=" + this.tokenvalue + "&page_id=" + this.page_id, fd).subscribe((data) => {
                console.log(data)
                this.edit_note = false;
                this.notesData(this.page_id)
            })
        }

    }

    addPadding() {
        $("#chaptersection").css("padding-right", "50px !important");
        console.log("reaching");
    }

    modalClose() {
        this.edit_note = false;
        this.httpclient.get(this.shareservice.api + "auth/user_notification?token=" + this.tokenvalue + "&fk_page_id=" + this.page_id).subscribe((data) => {
            console.log(data)
        })
    }

    chapterCompleted(v) {
        if (this.service_enforce) {
            this.chapter_comp_value = v
        }
    }

    showHideUploadResult(v) {
        console.log(v)
        this.show_assignment = v.show_assign;
        this.allow_upload_assign = v.allow_uplaod;
        this.upload_access = v.show_assign
    }

    showHideNotes(v) {
        this.show_notes = v.isnoteallowed;
        this.note_access = v.notes_access;
        this.notification = v.notification
    }

    exit() {
        this.router.navigate([this.exit_redirect])
    }

    forEnforceCheck() {
        if (localStorage.getItem('service_enforce') == '1') {
            this.forEnforce();
        }
    }

    forEnforce() {
        let params = new HttpParams().set('type', 'module_chapter');
        params = params.set('service_slug', this.serviceslug);
        params = params.set('module_id', this.module_id);
        params = params.set('mod_or_chapter_id', this.chapter_id);
        params = params.set('cluster_id', this.cluster_id);
        this.httpclient.get(this.api + "auth/insert_module_chapter_blur?token=" + this.tokenvalue, {params: params}).subscribe((data) => {
            console.log(data)
        }), (error) => {
            console.log(error)
        }
    }

    ngOnDestroy() {
        $('.modal-backdrop').removeClass('fade')
        $('.modal-backdrop').removeClass('fade')
        $('.modal-backdrop').removeClass('modal-backdrop')
        if (this.note_modal_open) {
            this.httpclient.get(this.shareservice.api + "auth/user_notification?token=" + this.tokenvalue + "&fk_page_id=" + this.page_id).subscribe((data) => {
                console.log(data)
            })
        }

    }

    splitImageName(path) {
        var parts = path.split("/");
        return parts[parts.length - 1];
    }

    overviewPage(v) {
        console.log(v.pid)
        this.overview_page = v.pid;
    }

}

export interface pagedatadetails {
    brand: any[];
    next_array: any;
    next_text: any;
    active_at: any;
    color: any[];
    counter: number;
    page: any[];
    pagedata: any[];
    next_page?: any;
    next_module?: any;
    service?: any;
    hexacode: any;
    font_code: any;
    title_code: any;
    notes_allowed: any;

}

export interface userpagetrack {
    status: any;
}

export interface getpdf {
    data: any
}

export interface assignment {
    assignments: any;
    users: any;
}

export interface getnotes {
    all_notes: any;
    data: any[];
    my_notes: any;
}

export interface userroalaccess {
    role: any;
}

export interface singleuser {
    profiledata: any[];
}




// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // apiBaseUrl: "https://admin.designleadership.academy/api/",
  // apiEndpoint: "https://admin.designleadership.academy/api/",
  // imageurl:"https://admin.designleadership.academy/"

  apiBaseUrl: "https://stageadmin.designleadership.academy/api/",
  apiEndpoint: "https://stageadmin.designleadership.academy/api/",
  imageurl:"https://stageadmin.designleadership.academy/"

  // apiBaseUrl: "https://dev3.snsepro.com/clients/grow/public/api/",
  // apiEndpoint: "https://dev3.snsepro.com/clients/grow/public/api/",
  // imageurl:"https://dev3.snsepro.com/clients/grow/public/"

  // apiBaseUrl: "http://localhost:8080/api/",
  // apiEndpoint: "http://localhost:8080/api/",
  // imageurl:"http://localhost:8080/"

  // apiBaseUrl: "http://nginx:80/api/",
  // apiEndpoint: "http://nginx:80/api/",
  // imageurl:"http://nginx:80/"
};

// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
